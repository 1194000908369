@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500;700;800&family=Open+Sans:wght@400;600;800&display=swap');
/* CSS RESET */

:where(html, body, #root) {
	height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/* GLOBAL STYLES */
@layer base {
  html {
		font-family: 'M PLUS 1p', sans-serif;
		font-weight: 400;
  }

	input, select {
	font-family: open-sans, sans-serif;
	}
}

input[type="date"]::-webkit-calendar-picker-indicator {
	filter: invert(1) brightness(50%);
	cursor: pointer;
}

input[type="date"] {
	color: black !important;
}
input[type="date"]:disabled {
	color: rgb(159, 158, 158) !important;
}

/*select department option for CreateUserForm */
input[role = 'combobox'] {
	box-shadow: none !important;
}

.event-pointer {
	cursor: pointer;
}


/* CUSTOM CSS CLASSES */
/* nav active link border */

.active-link-border {
	clip-path: inset(5% 7% 87% 10%);
  background: #BDDFEC;
}

/* nav dropdown menu element */
.triangle {
	clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
	-webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangle-inner {
		position: absolute;
		top: 1px;
		left: 1px;
		right: 1px;
		bottom: 0px;
		background: white;
		-webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

/* Toastify message */
.Toastify__toast-container {
    max-width: 450px;
    width: 100%;
}

/* Calender */
/* Target the calendar body */

.custom-fullcalender-container .fc-view {
	box-shadow: 0px 5px 20px #00000014;
}

.fc-view {
  background-color: #ffffff;
	border-radius: 1rem;
	padding: 30px;
}

.fc .fc-timegrid-col.fc-day-today {
	background-color: #bddfec34;
}

.fc .fc-timegrid-slot-label {
	display: flex;
	justify-content: center;
}

.fc-timegrid-body, .fc .fc-timegrid .fc-daygrid-body, .fc-timegrid-slots table, .fc-timegrid-cols table, .fc-scrollgrid-sync-table   {
	width: 100% !important;
}

.fc .fc-toolbar.fc-header-toolbar {
	margin-bottom: 0 !important;
}

/*Target the event*/
.fc .fc-event {
	border-width: 2px
}

.fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover, .fc-daygrid-event:hover, .fc-event-main:hover {
	background: rgba(0, 0, 0, 0.059);
}

.fc-event-time {
	color: #929395;
}

.fc-event-title {
	color: #1D1A55;
}

.fc .fc-toolbar-title {
	font-size: 2.38rem;
	color: white;
	font-weight: bold;
	margin-right: 20px;
}

.fc-timegrid-slot-label-cushion, .fc-event-time  {
	font-family: 'Open Sans', sans-serif;
}

.fc-col-header {
	width: 100% !important;
}

/* Target the calendar buttons */
.fc .fc-prev-button, .fc .fc-next-button {
	background-color: transparent;
	border-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	outline: none;
	width: 40px;
	height: 40px;
}

.fc-toolbar-chunk {
	position: relative;
}

.fc .fc-prev-button{
	position: absolute;
	right:-30px;
	top: 11px;
	transition: color;
	transition-duration: 0.2;
}

.fc .fc-next-button{
	position: absolute;
	right:-80px;
	top: 11px;
}

.fc .fc-prev-button:hover, .fc .fc-next-button:hover {
	background-color: #BDDFEC;
	border: 1px solid transparent;
}

.fc .fc-dayGridMonth-button, .fc .fc-timeGridWeek-button, .fc .fc-timeGridDay-button  {
	background-color: transparent;
	margin-top: 20px;
	border: 1px solid transparent;
	transition: all 0.2s;
	margin-bottom: 16px;
}

.fc .fc-dayGridMonth-button {
	margin-right: 10px;
}

.fc .fc-dayGridMonth-button:hover, .fc .fc-timeGridWeek-button:hover, .fc .fc-timeGridDay-button:hover  {
	background-color: transparent;
	border: 1px solid transparent;
}

.fc .fc-button:focus, .fc .fc-button-primary:focus {
	outline: none;
	box-shadow: none;
}

.fc .fc-button-primary:disabled {
	color: white;
	background-color: white;
	border-color: none;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
	color: #0065BD;
	background-color: #BDDFEC;
	border: 1px solid #BDDFEC;
	border-radius: 5px;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
	box-shadow: none;
}

.fc .fc-col-header-cell-cushion {
	color: #1D1A55;
	font-size: 1.13rem;
	word-break: break-all;
}

.fc-toolbar-title {
	margin-left: 10px;
}


/* Target days in the calendar  */

.fc .fc-daygrid-day-top {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
}

.fc .fc-daygrid-day-number {
	font-size: 1.25rem;
	font-weight: bold;
	color: #223367;
	padding: 10px;
}

.fc .fc-daygrid-day.fc-day-today {
	background: transparent;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
	color: #BDDFEC;
	font-size: 1.5rem;
}
.fc .fc-timegrid-axis-frame{
	word-break: break-all;
}

.fc .fc-non-business {
	background: #b8bcbf21;
}
.fc-timegrid-col-frame {
	width: 100%;
}

/* Target scrollbar in the calendar  */

.fc-scroller::-webkit-scrollbar {
  width: 7px;
}

.fc-scroller::-webkit-scrollbar-track {
  background-color: #f8f8f8;
}

.fc-scroller::-webkit-scrollbar-thumb {
  background-color: #1D1A55;
  border-radius: 5px;
}

/*hiding arrows for table pagination input*/

.pagination-input::-webkit-outer-spin-button,
.pagination-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pagination-input {
  -moz-appearance: textfield;
}

@media (max-width: 1280px) {

	.fc .fc-toolbar-title {
		font-size: 2.38rem;
		color: #1D1A55;

	}
	.fc .fc-prev-button, .fc .fc-next-button {
		border: 1px solid #1D1A55;
	}

	.fc .fc-prev-button:hover, .fc .fc-next-button:hover {
		border: 1px solid #1D1A55;
	}

	.fc .fc-dayGridMonth-button, .fc .fc-timeGridWeek-button, .fc .fc-timeGridDay-button {
		background-color: transparent;
		color: #1D1A55;
		border: 1px solid white;
	}

	.fc .fc-dayGridMonth-button:hover, .fc .fc-timeGridWeek-button:hover, .fc .fc-timeGridDay-button:hover  {
		background-color: transparent;
		color: #1D1A55;
	}

	.fc .fc-button-primary {
		color:  #1D1A55;
		border: 1px solid transparent;
	}
	.fc .fc-button-primary:hover  {
		color:  #1D1A55;
		border: 1px solid transparent;
	}

	.fc .fc-col-header-cell-cushion {
		font-size: 1rem;
	}
}

@media (max-width: 530px) {
  .custom-fullcalender-container .fc-view {
    box-shadow: none;
  }
  
  .fc-view {
    padding: 0;
  }

	.fc .fc-toolbar {
		display: flex;
		flex-direction: column;
	}
	
	.fc .fc-toolbar:first-child {
		display: flex;
		flex-direction: column;
	}

	.fc .fc-toolbar-title {
		font-size: 25px;
		margin: 0;
		white-space: nowrap;
	}

	.fc .fc-prev-button{
		position: static;
	}
	
	/* TODO: refactor - not good for UI */
	.fc .fc-next-button{
		top: 38px;
		right: -10px;
	}
	.fc .fc-prev-button{
		left: -10px;
	}
	
}

/* For form number input */

.PhoneInputInput {
	outline: none !important;
	border:none !important;
	box-shadow: none !important;
}

.PhoneInputCountryIcon--border  {
	box-shadow: 0 0 0 #1D1A55 !important;
	background-color: #1D1A55 !important;
}

.PhoneInputCountrySelect:focus +
.PhoneInputCountryIcon +
.PhoneInputCountrySelectArrow {
	color: #0065BD !important;
	border-color: #0065BD;
}

/* For form checkbox input */
.custom-checkbox, .custom-checkbox-calender-filter {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: white;
  outline: none !important;
  border: 1px solid #B8BCBF;
	cursor: pointer !important;
	outline: none !important;
}

.custom-checkbox-all-day  {
	appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
	margin-left: 1rem;
  border-radius: 4px;
  background-color: white;
  outline: none !important;
  border: 1px solid #B8BCBF;
	cursor: pointer !important;
}

.custom-checkbox-all-day:checked {
  background-color: white !important;
	border: 1px solid #B8BCBF !important;
  background-image: url('src/assets/images/done.svg');
	background-size: 13px;
}

.custom-checkbox-all-day:disabled {
	cursor: not-allowed !important;
}

.custom-checkbox-calender-filter:checked {
  background-color: white !important;
	border: 1px solid #B8BCBF !important;
	background-image: url('src/assets/images/checked-filter.svg');
	background-size: 15px;
}

.custom-checkbox:checked {
  background-color: white !important;
	border: 1px solid #B8BCBF !important;
  background-image: url('src/assets/images/done.svg');
	background-size: 15px;
}

.custom-checkbox:focus, .custom-checkbox-all-day:focus, .custom-checkbox-calender-filter:focus  {
	box-shadow: none !important;
}

@media (max-width: 768px) {
	.custom-checkbox-all-day{
		margin-left: 0;
	}
}

/* Remove arrow for disabled form checkbox input */
.disabled-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
}


/* Settings for SimpleBar */

.simplebar-scrollbar:before {
	background-color: #1D1A55 !important;
}
.simplebar-scrollbar.simplebar-visible:before {
	opacity: 1 !important;
}

.css-1c9jd2h-control {
	box-shadow: none !important
}

.css-1c9jd2h-control:hover {
	border-color: #0065BD !important;

}

/* DONT TOUCH THIS IS FOR REACT QUILL EDITOR */

.ql-toolbar {
    border-radius: 10px 10px 0 0;
}

.ql-container {
    border-radius: 0 0 10px 10px;
    height: 150px;
}

:where(#emailTemplate) h1 {
    font-size: 2rem;
}

:where(#emailTemplate) h2 {
    font-size: 1.5rem;

}:where(#emailTemplate) h3 {
    font-size: 1.25rem;
}

@media (min-width: 767px) {
    .ql-container {
        height: 250px;
    }
}






